import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer" id="dashFooter">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-md-center justify-content-sm-between py-2 w-100">
            <span className="text-center text-sm-left d-block d-sm-inline-block">
              Copyright ©{" "}
              <a
                href="https://khiladiludo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                khiladiludo.com
              </a>{" "}
              2024
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
